import * as Sentry from '@sentry/vue';
import { router } from '@inertiajs/vue3';

/**
 * Initialize Sentry
 *
 * @param {Vue | Vue[] | undefined} app - The Vue app
 *
 * @returns {void}
 */
export const initializeSentry = (app) => {
	Sentry.init({
		app,
		dsn: import.meta.env.VITE_SENTRY_VUE_DNS,
		environment: import.meta.env.VITE_APP_ENV,
		debug: import.meta.env.VITE_APP_DEBUG === 'true',
		trackComponents: true,
		hooks: ['activate', 'mount', 'update', 'unmount'],
		attachStacktrace: true,
		integrations: [
			Sentry.browserTracingIntegration({
				enableInp: true,
				instrumentNavigation: false,
				instrumentPageLoad: false,
			}),
			Sentry.replayIntegration(),
			Sentry.browserProfilingIntegration(),
			Sentry.thirdPartyErrorFilterIntegration({
				// Specify the application keys that you specified in the Sentry bundler plugin
				filterKeys: [import.meta.env.VITE_SENTRY_APPLICATION_KEY],

				// Defines how to handle errors that contain third party stack frames.
				// Possible values are:
				// - 'drop-error-if-contains-third-party-frames'
				// - 'drop-error-if-exclusively-contains-third-party-frames'
				// - 'apply-tag-if-contains-third-party-frames'
				// - 'apply-tag-if-exclusively-contains-third-party-frames'
				behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
			}),
		],
		sampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE || 1.0,
		tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.1,
		tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_URL],
		replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SESSIONS_SAMPLE_RATE || 0.1,
		replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE || 0.1,
		profilesSampleRate: import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE || 0.1,
	});

	/**
	 * Sentry Performance Monitoring with Inertia.js
	 *
	 * see: https://github.com/getsentry/sentry-javascript/discussions?discussions_q=Inertia.js
	 */

	let name = route().current();

	router.on('before', () => {
		const client = Sentry.getClient();
		const newName = route().current();

		if (newName !== name) {
			name = newName;
			Sentry.startBrowserTracingNavigationSpan(client, {
				op: 'navigation',
				name,
				attributes: {
					[Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route',
				},
			});
		}
	});

	router.on('finish', () => {
		name = route().current();
		const span = Sentry.getActiveSpan();
		const op = span && Sentry.spanToJSON(span).op;

		if (op === 'pageload' || op === 'navigation') {
			span.setName(name);
		}
	});
};

/**
 * Set the user context in Sentry
 *
 * @param {object} user
 *
 * @returns {void}
 */
export const setUserContext = (user) => {
	if (! user) {
		Sentry.setUser(null);
	}

	Sentry.setUser({
		id: user.id,
		username: user.name,
		email: user.email,
		language: user.language_code,
		timezone: user.timezone,
		two_factor_enabled: user.two_factor_enabled,
		current_workspace: user.current_workspace,
		current_workspace_membership: user.current_workspace_membership,
	});
};

/**
 * Set the user settings in Sentry
 *
 * @returns {void}
 */
export const setUserSettings = () => {
	Sentry.setTag('resolution', `${window.screen.width} × ${window.screen.height}`);
	Sentry.setTag('viewport', `${window.innerWidth} × ${window.innerHeight}`);
	Sentry.setTag('back_forward_cache', bfCacheUsed() ? 'used' : 'not used');

	Sentry.setContext('Additional Browser Data', {
		'Resolution': `${window.screen.width} × ${window.screen.height}`,
		'Viewport': `${window.innerWidth} × ${window.innerHeight}`,
		'Languages': `${navigator.languages.join(', ')}`,
	});
};

/**
 * Check if the back forward cache is used
 *
 * @returns {boolean | void}
 */
const bfCacheUsed = () => {
	window.addEventListener('pageshow', (event) => {
		return event.persisted;
	});
};
