import { usePage } from '@inertiajs/vue3';

/**
 * Get the translation for the given key.
 *
 * @param {string} key
 * @param {object} replace
 *
 * @returns {string}
 */
export function __(key, replace = {}) {
	// translation variable is more of a translation scope than a translation itself
	// It will be used to loop through the keys and stores the current translation scope
	// in the last iteration it will be the translation itself
	let translationKeys = usePage().props.language;
	let translation = translationKeys[key] ?? key;

	if (typeof replace === 'string') {
		// no quotes around the regex because it would be interpreted as a string
		return translation
		// replace all placeholders in format :Placeholder with the given values
		// needs to be done before the next replace because the next replace would break the format
			.replace(/:[A-Z][a-z]+/g, replace[0].toUpperCase() + replace.slice(1))
		// replace all placeholders in format :PLACEHOLDER with the given values
			.replace(/:[A-Z]+/g, replace.toUpperCase())
		// replace all placeholders in format :placeholder with the given values
			.replace(/:[a-z]+/g, replace);
	}

	// Replace all placeholders in format :placeholder with the given values
	// Example: __('messages.welcome', { name: 'John Doe' }) with messages.welcome = 'Hello :name' will return 'Hello John Doe'
	// replace have to be an object with key-value pairs where the key is the placeholder name and the value is the value to replace
	Object.keys(replace).forEach(function (key) {
		if (key[0] === key[0].toUpperCase()) {
			replace[key] = replace[key][0].toUpperCase() + replace[key].slice(1);
		}
		if (isOnlyUppercase(key)) {
			replace[key] = replace[key].toUpperCase();
		}

		translation = translation
		// replace all placeholders in format :PLACEHOLDER with the given values
			.replace(`:${key.toUpperCase()}`, replace[key])
		// replace all placeholders in format :placeholder with the given values
			.replace(`:${key.toLowerCase()}`, replace[key])
		// replace all placeholders in format :Placeholder with the given values
			.replace(`:${key[0].toUpperCase()}${key.slice(1)}`, replace[key]);
	});

	return translation;
}

/**
 * Check if the given string is only uppercase
 *
 * @param {string} str
 *
 * @returns {boolean}
 */
export function isOnlyUppercase(str) {
	return str.toUpperCase() === str;
}
